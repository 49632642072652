<template>
<!--  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">-->
  <div class="d-flex flex-column flex-root h-100">
    <b-alert
        :show="alert.show"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 100001"
        dismissible
        fade
        :variant="alert.color"
        @dismiss-count-down="countDownChanged"
        @dismissed="alert.show === 0"
    >
      {{ alert.message }}
    </b-alert>
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile :totalUnread="totalUnread" :userIconClicked="userIconClicked"></KTHeaderMobile>
    <!-- end:: Header Mobile -->

<!--    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>-->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page ">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader :getPush="getPush" :totalUnreadCheck="totalUnreadCheck"></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->
          <!-- begin:: Content Body -->
          <div class="body-start d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>

    <KTStickyBottom></KTStickyBottom>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import KTAside from '@/view/layout/aside/Aside.vue';
import KTHeader from '@/view/layout/header/Header.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTSubheader from '@/view/layout/subheader/Subheader.vue';
import KTFooter from '@/view/layout/footer/Footer.vue';
import KTScrollTop from '@/view/layout/extras/ScrollTop';
import KTStickyBottom from '@/view/layout/sticky/StickyBottom'
import {GET_MY_PROFILE, SET_AUTH, PURGE_AUTH, SET_PROFILE, REFRESH_TOKEN} from '@/core/services/store/auth.module';
import {getItem, lengthCheck, isSuccess} from '@/core/services/funcs';
// import Loader from "@/view/content/Loader.vue";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTSubheader,
    KTFooter,
    KTScrollTop,
    KTStickyBottom
    // Loader
  },
  data() {
    return {
      getPush : 0,
      totalUnread : 0
    }
  },
  beforeMount() {
    // show page loading
    // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      if((window.location.pathname).includes("email-confirm")){
        return
      }
      if(this.loginStayYn === 'Y' ) {
        this.refreshToken()
      } else {
        this.$store.commit(PURGE_AUTH);
        this.$router.push({ name: "signin", query: {returnUrl: window.location.pathname + window.location.search}});
      }
    } else {
      this.$store.dispatch(GET_MY_PROFILE)
      .then(resp => {
        if(lengthCheck(resp)){
          this.$store.commit(SET_PROFILE, getItem(resp))
          if(this.isMobile){
            this.getPush++
          }
        } else {
          this.$store.commit(PURGE_AUTH);
          this.$router.push({ name: "signin" });
        }
      })
      .catch(e => {
        if(this.loginStayYn === 'Y' ) {
          this.refreshToken()
        } else {
          console.error(e);
          this.$store.commit(PURGE_AUTH);
          this.$router.push({ name: "signin", query: {returnUrl: window.location.pathname + window.location.search}});
        }
      });
    }
    // Simulate the delay page loading
  },
  methods: {
    refreshToken() {
      this.$store.dispatch(REFRESH_TOKEN)
      .then(resp => {
        if(isSuccess(resp)){
          this.$store.commit(SET_AUTH, {uuid: resp.uuid, mno: resp.mno, authToken: resp.authToken, refreshToken: resp.refreshToken, loginStayYn: this.loginStayYn});
          window.location.href = window.location.pathname;
        } else {
          this.$store.commit(PURGE_AUTH);
          this.$router.push({ name: "signin" });
        }
      }).catch(e => {
        console.error(e);
        this.$store.commit(PURGE_AUTH);
        this.$router.push({ name: "signin" });
      })
    },
    countDownChanged (countDown) {
      this.alert.show = countDown
    },
    totalUnreadCheck(totalUnread){
      this.totalUnread = totalUnread
    },
    userIconClicked(){
      this.getPush++
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "alert",
      "isMobile",
      "loginStayYn"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
